import { Product, ProjectConstructorConfig, Themes } from '../types';
import { validateEnum, validateProps } from './validateProps';

type componentConstructorArgs = {
  element: HTMLElement;
  theme?: Themes;
};

interface DovetailComponent {
  render: <T>(props: T) => Promise<void>;
  unmount: () => Promise<void>;
}

const componentFactory = (
  getComponentModule: () => Promise<unknown>,
  config: ProjectConstructorConfig
) =>
  function ({
    element,
    theme = config.theme || Themes.default,
  }: componentConstructorArgs): DovetailComponent {
    if (!element) {
      throw new Error(`Cannot inject because provided element was not found.`);
    }

    validateProps(
      { product: config.product, theme },
      { product: 'string', theme: 'string' }
    );
    validateEnum(
      { product: config.product, theme },
      { product: Product, theme: Themes }
    );

    let componentInstanceArgs = { ...config, element, theme };

    type DynamicComponent = {
      default: <T>(componentProps: T) => void;
    };

    return {
      render: async function <T>(props: T): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { default: component }: DynamicComponent =
          (await getComponentModule()) as DynamicComponent;

        componentInstanceArgs = {
          ...componentInstanceArgs,
          ...props,
        };

        element.style.overflow = 'hidden';

        return component(componentInstanceArgs);
      },
      unmount: async (): Promise<void> => {
        const { unmountComponent } = await import(
          /* webpackChunkName: "unmountComponent" */ '../utilities/unmountComponent'
        );

        unmountComponent(element);
      },
    };
  };

export default componentFactory;
