export var Product;
(function (Product) {
    Product["E1"] = "e1";
    Product["LEANKIT"] = "leankit";
    Product["PLATFORMA"] = "platforma";
    Product["PROJECTPLACE"] = "projectplace";
    Product["PROJECTPLACE_WHITEBOARD"] = "projectplace_whiteboard";
    Product["PROJECTPLACE_LOGBOOK"] = "projectplace_logbook";
    Product["TEAM_TAP"] = "teamtap";
    Product["ROADMAP"] = "roadmaps";
    Product["LAUNCHPAD"] = "launchpad";
    Product["COPILOTADMIN"] = "copilot_admin";
    Product["PPMPRO"] = "ppmpro";
    Product["E1_PRM"] = "e1_prm";
    Product["E1_WORK"] = "e1_work";
    Product["ADAPTIVEWORK"] = "adaptivework";
    Product["GROUPS"] = "groups";
})(Product || (Product = {}));
export var Project;
(function (Project) {
    Project["OKR"] = "okr";
    Project["ROADMAP"] = "roadmap";
    Project["TEAMTAP"] = "teamtap";
    Project["NOTIFICATION"] = "notification";
    Project["PRODUCTSWITCHER"] = "productswitcher";
    Project["WHITEBOARD"] = "whiteboard";
    Project["LAUNCHPAD"] = "launchpad";
    Project["LOGBOOK"] = "logbook";
})(Project || (Project = {}));
export var HostingMethod;
(function (HostingMethod) {
    HostingMethod["STANDALONE"] = "standalone";
    HostingMethod["EMBEDDED"] = "embedded";
})(HostingMethod || (HostingMethod = {}));
