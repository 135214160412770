export enum Product {
  E1 = 'e1',
  LEANKIT = 'leankit',
  PLATFORMA = 'platforma',
  PROJECTPLACE = 'projectplace',
  PROJECTPLACE_WHITEBOARD = 'projectplace_whiteboard',
  PROJECTPLACE_LOGBOOK = 'projectplace_logbook',
  TEAM_TAP = 'teamtap',
  ROADMAP = 'roadmaps',
  LAUNCHPAD = 'launchpad',
  COPILOTADMIN = 'copilot_admin',
  PPMPRO = 'ppmpro',
  E1_PRM = 'e1_prm', //portfolios strategy
  E1_WORK = 'e1_work', //portfolios work
  ADAPTIVEWORK = 'adaptivework', //adaptive work
  GROUPS = 'groups',
}

export enum Themes {
  default = 'default',
  leankit = 'leankit',
  e1 = 'e1',
  teamtap = 'teamtap',
}

export enum Project {
  OKR = 'okr',
  ROADMAP = 'roadmap',
  TEAMTAP = 'teamtap',
  NOTIFICATION = 'notification',
  PRODUCTSWITCHER = 'productswitcher',
  WHITEBOARD = 'whiteboard',
  LAUNCHPAD = 'launchpad',
  LOGBOOK = 'logbook',
}

export enum HostingMethod {
  STANDALONE = 'standalone',
  EMBEDDED = 'embedded',
}

export interface ProjectConstructorConfig {
  product: Product;
  theme?: Themes;
}

export type PlatformaAuthTokenClosure = () => Promise<AuthTokens>;

export type GenericComponent = {
  element: HTMLElement;
};

export interface AuthTokens {
  token?: string; // temporary, until LK returns idToken in their closure
  accessToken: string;
  idToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
}

export interface PlatformaJWT {
  iss: string;
  exp: number;
  planview_env_selector?: string;
  planview_admin_url?: string;
  planview_tenant_group_id?: string;
  planview_user_id?: string;
}

export type ConfigProps = {
  apiToken?: string | PlatformaAuthTokenClosure;
  product: Product;
  theme?: Themes;
  platformaAuthTokenClosure?: PlatformaAuthTokenClosure;
  environment?: Environment;
  sessionTimeout?: number;
};

export type Environment = 'dev' | 'stg' | 'eu' | 'pd' | 'ap';

export type DefaultComponentExportProps = ConfigProps & GenericComponent;

export type DovetailStandaloneEnvironment =
  | 'dev'
  | 'staging'
  | 'prod-ap'
  | 'prod-eu'
  | 'prod-us';

export interface StandAloneDovetailProps extends GenericComponent {
  authTokenClosure: () => Promise<AuthTokens>;
  getUserData: ((id: string) => Promise<UserData>) &
    (() => Promise<StandAloneUserData>);
  backendApiUrl: string;
  notificationSrcUrl?: string;
  notificationApiUrl?: string;
  baseScriptUrl: string;
  environment: DovetailStandaloneEnvironment;
}

export interface UserData {
  id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  avatar: string;
}

export interface StandAloneUserData extends UserData {
  user_locale?: {
    locale: string;
    date_format: string;
  };
  current_application: PVApplication;
  applications: PVApplication[];
  customer: Customer;
}

export interface PVApplication {
  name: string;
  description: string;
  envSelector: string;
  licenseName?: string;
  url: string;
  navigateUrl: string;
  title: string;
  role?: string;
  tenantGroupId?: string;
  softwareVersion?: string;
}

interface Customer {
  customerId: string;
  customerName: string;
}

export interface Locale {
  date_format: string;
  locale: string;
  start_of_week: 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 is sunday, 1 is monday
}

export type PendoEventProps = {
  name: string;
  metadata?: {
    [key: string]: string | string[];
  };
};
export type PendoMetadata = {
  metadata: PendoEventProps['metadata'];
};

export type NotificationConfig = {
  notificationApiUrl: string;
  pubnubSubscriberKey: string;
  launchDarklyClientKey: string;
};

export type RoadmapConfig = {
  hasuraApiUrl: string;
};

export type AppConfig = {
  notification: NotificationConfig;
  roadmap?: RoadmapConfig;
};

export type ProjectConfig = {
  config: AppConfig;
};
