import { componentFactory } from '@dovetail/core/initialization';
import { ProjectConstructorConfig } from '@dovetail/core/types';

const exportableComponents: Record<string, () => Promise<unknown>> = {
  LaunchPadStandalone: () =>
    import(
      /* webpackChunkName: "LaunchPadStandalone" */ './app/LaunchPadStandaloneScreen'
    ),
  LaunchPad: () =>
    import(
      /* webpackChunkName: "LaunchPadEmbeddedScreen" */ './app/LaunchPadEmbeddedScreen'
    ),
  AdminScreen: () =>
    import(/* webpackChunkName: "AdminScreen" */ './admin/AdminScreen'),
};

export default class LaunchPad {
  components: Record<string, ReturnType<typeof componentFactory>>;

  constructor(readonly config: ProjectConstructorConfig) {
    this.config = config;
    this.components = Object.keys(exportableComponents).reduce(
      (accumulator, componentName) => ({
        ...accumulator,
        [componentName]: componentFactory(
          exportableComponents[componentName],
          this.config
        ),
      }),
      {}
    );
  }
}
